import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { Column, PageTitle, Title, Content } from "../globals/style";

const ContentWrapper = styled.div`
  max-width: 1440px;
  background: #1e1d22;
  margin: 0 auto;
  padding: 5% 0;
  @media screen and (max-width: 991px) {
    padding: 2em;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #1e1d22;
`;
const Map = styled.iframe`
  height: 60vh;
  width: 60vh;
  border: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  position: relative;
  max-width: calc(90vw - 2em);
  z-index: 2;
  transform-origin: top left;
  transform: scale(${(props) => (!props.loaded ? 0 : 1)});
  transition: all 0.5s ease;
  @media screen and (max-width: 991px) {
    height: 35vh;
    width: 100vw;
    max-width: 100vw;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ContentHolder = styled(Row)`
  text-align: left;
  display: block;
  width: 100%;
`;
const SmallerContent = styled(Content)`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  &:after {
    content: "";
    width: 10px;
    height: 95%;
    box-sizing: border-box;
    top: 0;
    left: 0;
    background: #efefef;
    margin-left: -30px;
    margin-top: 5px;
    position: absolute;
  }
`;
const MapWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    height: 90%;
    width: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    margin-top: -2em;
    margin-left: -2em;
    background: #efefef;
    position: absolute;
  }
  @media screen and (max-width: 991px) {
    &:after {
      margin-top: 0;
      margin-left: 0;
      height: 90%;
      width: 100%;
    }
  }
`;
const KontaktTitle = styled(PageTitle)`
  @media screen and (min-width: 992px) {
    margin: 0em auto 0 1em;
  }
`;
const KontaktPage = () => {
  const [loaded, Maploaded] = useState(false);
  const {
    allStrapiKontaktinfo: {
      edges: {
        0: { node: data },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        allStrapiKontaktinfo {
          edges {
            node {
              Address
              Content
              Otherinfo
            }
          }
        }
      }
    `
  );
  return (
    <Layout
      title="Kontakt"
      description="Kom i kontakt med VI§VE advokater idag!"
      keywords={[
        "Kontakt",
        "Ring",
        "Email",
        "adresse",
        "advokat",
        "advokathjelp",
      ]}
    >
      <Wrapper>
        <ContentWrapper>
          <KontaktTitle reverse>Kontakt oss</KontaktTitle>
          <Row>
            <Column>
              <MapWrapper>
                <Map
                  loaded={loaded}
                  width="500"
                  height="500"
                  onLoad={() => Maploaded(true)}
                  src={`https://maps.google.com/maps?q=${encodeURI(
                    data.Address
                  )}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                />
              </MapWrapper>
            </Column>
            <Column style={{ padding: "2em 0" }}>
              <ContentHolder>
                <Title reverse>Detaljer</Title>
                <Content reverse>{data.Content}</Content>
              </ContentHolder>
            </Column>
          </Row>
          <Row>
            <SmallerContent reverse>{data.Otherinfo}</SmallerContent>
          </Row>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  );
};

export default KontaktPage;

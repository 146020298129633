import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Image from "gatsby-image";

export const Column = styled.div`
  height: 100%;
  padding: 1em;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: calc(50vh - 40px);
    flex-basis: 100%;
    flex: 1;
  }
`;

export const Title = styled.h2`
  opacity: 0.87;
  color: ${props => (props.reverse ? "#efefef" : "#000")};
  font-family: "Raleway";
  font-size: 2.5em;
`;
export const Content = styled(ReactMarkdown)`
  opacity: 0.76;
  color: ${props => (props.reverse ? "#efefef" : "#000")};
  font-family: "Raleway";
  font-size: 1.2em;
  line-height: 1.6;
  letter-spacing: 0.07em;
`;
export const CircularImage = styled(Image)`
  border-radius: 50%;
`;
export const PageTitle = styled.h1`
  opacity: 0.87;
  color: ${props => (props.reverse ? "#efefef" : "#000")};
  font-family: "Raleway";
  font-size: 3em;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 20%;
    height: 10px;
    background: #3f6564;
    @media screen and (max-width: 991px) {
      width: 40%;
    }
  }
`;
